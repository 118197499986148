<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
            <v-icon>mdi-chevron-double-right</v-icon> LISTA USUARIOS
            <v-icon>mdi-chevron-double-right</v-icon> Crear usuario
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <create></create>
  </v-container>
</template>

<script>
import create from "./users/Create.vue";

export default {
  name: "newUser.vue",
  components: { create },
};
</script>

<style scoped></style>
